import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { Form } from 'informed';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Message, Checkbox } from '@corratech/form-components';
import { PasswordField } from '@corratech/password-field';
import { Util } from '@magento/peregrine';
import {
    AuthStore,
    CartStore,
    LoaderStore,
    getUserDetails,
    useGlobalOptions
} from '@corratech/context-provider';
import { CmsBlockGroup } from '@corratech/cms-display';
import createCustomerIncheckout from '../graphql/createCustomerIncheckout.graphql';
import './OrderConfirmation.less';
import { EmptyCartCheckoutBlock } from '@corratech/checkout';
import { TrackPurchase } from 'ModulesPath/FacebookConversion/TrackPurchase';
import sendOrderDataToOrdergroove from 'ModulesPath/App/Queries/sendOrderDataToOrdergroove.graphql';
import { useDataLayerAction } from '@corratech/tag-manager';
import {
    orderGrooveOptins,
    clearOrderGrooveOptins,
    getCartIds
} from 'UtilPath/orderGroove';
import { Link } from 'react-router-dom';

export default function OrderConfirmation(props) {
    const {
        setEnteredPassword,
        enteredPassword,
        joinRewards,
        setJoinRewards
    } = props;
    const [t] = useTranslation();

    const dataLayerAction = useDataLayerAction();

    const storage = new Util.BrowserPersistence();

    const history = useHistory();

    const { authState, dispatch: authStateDispatch } = useContext(AuthStore);

    const { cartState, dispatch } = useContext(CartStore);

    const LoadingIndicator = useContext(LoaderStore);

    const [passwordIsValid, setPasswordIsValid] = useState(false);

    const [mutationErrorState, setMutationErrorState] = useState(null);

    const [loadingsignIn, setLoadingsignIn] = useState(false);

    const [password, setPassword] = useState('');

    const options = useGlobalOptions();

    const config = {
        isDuelEnabled: options.storeConfig.duel_enabled,
        duelSlug: options.storeConfig.duel_slug,
        shopMyEnabled: options.storeConfig.shop_my_enabled,
        grinEnabled: options.storeConfig.grin_enabled,
        constructorBeaconEnabled: options.storeConfig.constructor_beacon_enabled
    };

    const orderDetails =
        cartState &&
        cartState.placedOrder &&
        cartState.placedOrder.placeOrder &&
        cartState.placedOrder.placeOrder.order;

    const firstname = orderDetails && orderDetails.customer_firstname;
    const lastname = orderDetails && orderDetails.customer_lastname;
    const postcode = orderDetails && orderDetails.customer_postcode;
    const country = orderDetails && orderDetails.customer_country;
    const email = orderDetails && orderDetails.customer_email;
    const isGuest = orderDetails && orderDetails.is_guest_customer;
    const order_id = orderDetails && orderDetails.increment_id;
    const OGProducts = orderGrooveOptins();
    const currency = orderDetails && orderDetails.grand_total.currency;
    const cartTotal = orderDetails && orderDetails.grand_total.value;
    const couponCode = orderDetails && orderDetails.coupon_code;

    const callTrackPurchase = TrackPurchase();

    const [
        sendOrderToOrdergroove,
        { loading: orderGrooveLoading }
    ] = useMutation(sendOrderDataToOrdergroove, {
        onCompleted: ({ sendOrderDataToOrdergroove }) => {
            console.log(sendOrderDataToOrdergroove);
            clearOrderGrooveOptins();
        },
        onError: error => {
            setMutationErrorState(error.message.split('GraphQL error: ')[1]);
            setLoadingsignIn(false);
        }
    });

    const shopMyOrderTracking = () => {
        if (config.shopMyEnabled !== '1') {
            return;
        }

        const cookies = document.cookie.split(';').reduce((res, cookie) => ({
            ...res,
            [(cookie.split('=')[0] || '').trim()]: (cookie.split('=')[1] || '').trim()
        }), {});
        const {sms_click_id, sms_click_time} = cookies;
        const code = orderDetails.coupon_code || '';
        if (sms_click_id || code) {
            fetch(`https://api.shopmy.us/api/order_confirmation`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    orderAmount: orderDetails.grand_total.value,
                    orderId: orderDetails.increment_id,
                    clickId: sms_click_id,
                    currency: orderDetails.grand_total.currency,
                    is_returning_customer: false,
                    page_url: window.location.href,
                    code
                })
            }).then(r => r.json())
                .then(result => {
                    const now = new Date();
                    document.cookie = `sms_click_id=;expires=${now.toUTCString()};path=/;`;
                    document.cookie = `sms_click_time=;expires=${now.toUTCString()};path=/;`;
                });
        }
    }

    const grinOrderTracking = () => {
        if (config.grinEnabled !== '1') {
            return;
        }
        window.Grin = window.Grin || [];
        const orderNumber = orderDetails.increment_id;
        const amount = orderDetails.subtotal?.value - Math.abs(orderDetails.discount_amount?.value || 0);
        window.Grin.push(['conversion', amount, {order_number: orderNumber}]);
    }

    const constructorOrderTracking = () => {
        if (!config.constructorBeaconEnabled) {
            return;
        }
        let orderData = {
            items: [],
            order_id: orderDetails.increment_id,
            revenue: orderDetails.subtotal_incl_tax?.value,
        };
        orderDetails.items.forEach(item => {
            orderData.items.push(
                {
                    item_id: item.parent_sku ? item.parent_sku : item.sku,
                    variation_id: item.parent_sku ? item.sku : '',
                    price: item.price_incl_tax?.value,
                    count: item.quantity_ordered,
                }
            );
        });
        window.cnstrcPurchaseData = orderData;
    }

    const useConstructorClearOnNavigate = () => {
        useEffect(() => {
            const clearData = () => {
                window.cnstrcPurchaseData = null;
            };
            const unlisten = history.listen(() => {
                clearData();
            });
            return () => {
                unlisten();
                clearData();
            };
        }, [history]);
    };

    const sendOrderDetailsToABTasty = () => {
        if (!window.abtasty) {
            console.error('AB Tasty is not available');
            return;
        }

        let transactionData = {
            tid: orderDetails.increment_id,
            ta: 'Purchase',
            tr: orderDetails.grand_total.value,
            ts: orderDetails.shipping_amount.value,
            tt: orderDetails.tax_amount.value,
            tc: orderDetails.grand_total.currency,
            tcc: orderDetails.coupon_code || '',
            pm: orderDetails.payment_method_name,
            sm: orderDetails.shipping_description,
            icn: orderDetails.items.length
        };

        window.abtasty.send('transaction', transactionData);

        orderDetails.items.forEach(item => {
            let itemData = {
                tid: orderDetails.increment_id,
                in: item.name,
                ip: item.price.value,
                iq: item.quantity_ordered,
                ic: item.sku
            };

            window.abtasty.send('item', itemData);
        });
    };

    useEffect(() => {
        if (orderDetails && orderDetails.id) {
            sendOrderDetailsToABTasty();
            shopMyOrderTracking();
            grinOrderTracking();
            constructorOrderTracking();
        }
    }, [orderDetails]);

    useConstructorClearOnNavigate();

    useEffect(() => {
        getUserDetails(authStateDispatch);

        const customerData = [
            {
                email: email,
                firstName: firstname,
                lastName: lastname,
                postalCode: postcode,
                country: country
            }
        ];
        if (OGProducts.length > 0) {
            if (orderDetails && orderDetails.id) {
                clearOrderGrooveOptins();
            }
        }

        dataLayerAction({ type: 'ENHANCED_CONVERSION', data: customerData });

        const clearCart = () => {
            dispatch({
                type: 'CLEAR_PLACED_ORDER'
            });
        };

        if (orderDetails && orderDetails.id) {
            callTrackPurchase(orderDetails.id);
        }

        return clearCart;
    }, []);

    const handleContinueShopping = () => {
        history.push('/');
    };

    const signInCustomer = async token => {
        try {
            setLoadingsignIn(true);

            storage.setItem('signin_token', token);

            authStateDispatch({
                type: 'SET_AUTH_TOKEN',
                token: token,
                justSignedIn: true
            });

            await getUserDetails(authStateDispatch);
        } catch (error) {
            setLoadingsignIn(false);
        } finally {
            setLoadingsignIn(false);
            history.push('/my-account');
        }
    };

    const onPasswordChange = value => {
        setEnteredPassword(value.password);
    };

    const [createCustomer, { loading }] = useMutation(
        createCustomerIncheckout,
        {
            onCompleted: ({ createCustomerIncheckout }) => {
                signInCustomer(createCustomerIncheckout.token);
            },
            onError: error => {
                setMutationErrorState(
                    error.message.split('GraphQL error: ')[1]
                );
                setLoadingsignIn(false);
            }
        }
    );

    const handleSubmit = formState => {
        if (!joinRewards && passwordIsValid) {
            setPassword(formState.password);

            createCustomer({
                variables: {
                    password: formState.password,
                    listrak_newsletter_opt_in:
                        formState?.listrak_newsletter_opt_in
                }
            });
        }
    };

    const getReferenceDetails = () => {
        const duelValue = JSON.parse(localStorage.getItem('duel-analytics'));
        if (duelValue != null) {
            const value = duelValue.duel_a.value;
            const time = duelValue.duel_a.time;
            const dateDiff = new Date() - new Date(time);
            const daydiff = dateDiff / (1000 * 60 * 60 * 24);
            if (daydiff < 30) {
                return String(value);
            } else {
                return '';
            }
        }
        return '';
    };

    const agreementContent = (
        <>
            <span>
                {t(`I agree to receive email communications from ELEMIS.`)}
            </span>
            <Link to="/privacy-policy" className={'privacy-link'}>
                {t(` Privacy Policy`)}
            </Link>
        </>
    );

    return (
        <>
            {cartState && cartState.placedOrder ? (
                <div className={'checkout-page order-confirmation-page'}>
                    <div
                        className={`${
                            isGuest && authState.isEmailAvailable
                                ? 'two-column'
                                : 'one-column'
                        } order-confirmation-container checkout-container container-width`}
                    >
                        {!!+config.isDuelEnabled && (
                            <Helmet>
                                <script>{`
                                window.duel =
                                    window.duel ||
                                    function() {
                                        (duel.q = duel.q || []).push(arguments);
                                };
                        
                                duel('analytics:attribution', {
                                    slug: '${config.duelSlug}',
                                    order: '${order_id}',
                                    total: ${cartTotal},
                                    currency: '${currency}',
                                    email: '${email}',
                                    promo: '${couponCode ?? ''}',
                                    affiliate: '${getReferenceDetails()}'
                                });
                            `}</script>
                            </Helmet>
                        )}
                        <div className={'left-column'}>
                            <h2 className={'checkout-title'} data-cs-mask>
                                {t('Thank You For Your Order')} {firstname}!
                            </h2>

                            <div className={'order-id-block'}>
                                <p data-cs-mask>
                                    {t(`Your order number is`)}: #{order_id}.
                                </p>
                                <p>
                                    {t(
                                        `We'll email you an order confirmation with details and tracking info.`
                                    )}
                                </p>
                                {OGProducts.length > 0 &&
                                    (orderGrooveLoading ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <p>
                                            {t(
                                                `Subscription has been recorded`
                                            )}
                                        </p>
                                    ))}
                                <Button
                                    type="submit"
                                    size="lg"
                                    variant="primary"
                                    disabled={orderGrooveLoading}
                                    onClick={handleContinueShopping}
                                >
                                    {t(`Continue Shopping`)}
                                </Button>
                            </div>
                        </div>
                        {isGuest && authState.isEmailAvailable && (
                            <div className={'right-column'}>
                                <div className={'order-confirmation-top-block'}>
                                    <div
                                        className={'order-confirmation-wrapper'}
                                    >
                                        <p className={'order-confirmation'}>
                                            {t(
                                                'You can track your order status by creating an account.'
                                            )}
                                        </p>

                                        <p
                                            className={
                                                'order-confirmation-email'
                                            }
                                        >
                                            {t('Email Address')}: {email}
                                        </p>
                                    </div>
                                </div>

                                <Form
                                    className={'order-confirmation-form'}
                                    onSubmit={handleSubmit}
                                >
                                    <PasswordField
                                        className={'input-password-wrapper'}
                                        {...{
                                            minLength: 8,
                                            minScore: 2,
                                            validate: {
                                                isRequired: true,
                                                validatePassword: true
                                            }
                                        }}
                                        changeCallback={data => {
                                            setMutationErrorState(null);
                                            setPasswordIsValid(data.isValid);
                                        }}
                                        inputProps={{
                                            name: 'password',
                                            autoComplete: 'off',
                                            placeholder: t(`Password*`),
                                            className: 'input-password'
                                        }}
                                        validatePasswordText={t(
                                            'A password must contain at least 3 of the following: ' +
                                                'lowercase, uppercase, digits, special characters.'
                                        )}
                                    />
                                    <div>
                                        <Checkbox
                                            field="listrak_newsletter_opt_in"
                                            id="listrak_newsletter_opt_in"
                                            label={
                                                <span>{agreementContent}</span>
                                            }
                                        />
                                    </div>
                                    {mutationErrorState && (
                                        <Message
                                            fieldState={{
                                                error: mutationErrorState
                                            }}
                                        />
                                    )}
                                    <Button
                                        type="submit"
                                        size="lg"
                                        variant="primary"
                                        block
                                        disabled={
                                            loading || loadingsignIn
                                                ? true
                                                : false
                                        }
                                    >
                                        {(() => {
                                            if (loading || loadingsignIn)
                                                return (
                                                    <span>
                                                        {t(`Creating...`)}
                                                    </span>
                                                );
                                            else {
                                                return (
                                                    <span>
                                                        {t(`Create an Account`)}
                                                    </span>
                                                );
                                            }
                                        })()}
                                    </Button>
                                </Form>

                                <div
                                    className={
                                        'order-confirmation-bottom-block'
                                    }
                                >
                                    <CmsBlockGroup
                                        identifiers={
                                            'Checkout_Confirmation_Page_Terms_Policy_Links'
                                        }
                                        loadingIndicator={<LoadingIndicator />}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <EmptyCartCheckoutBlock />
            )}
        </>
    );
}
