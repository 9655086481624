import React, { useContext, useEffect, useState } from 'react';
import {
    CartStore,
    LoaderStore,
    useSetNotification
} from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import setShippingMethodsOnCart from '../graphql/setShippingMethodsOnCart.graphql';
import { getCurrencySymbol } from '../util/getCurrencySymbol';
import './ShippingMethodForm.less';
import { CustomRadioButton } from '@corratech/form-components';
import { Alert } from 'react-bootstrap';
import { AlertTriangle as Warning } from 'react-feather';

export const ShippingMethodForm = ({
    shippingMsg,
    warningIcon,
    shippingMethodRef,
    invalidShippingMsg,
    setInvalidShippingMsg,
    isFromPaypal
}) => {
    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);
    const { clearNotification } = useSetNotification();
    const LoadingIndicator = useContext(LoaderStore);

    const [setShippingMethod, { data, loading }] = useMutation(
        setShippingMethodsOnCart
    );

    useEffect(() => {
        if (data) {
            const updatedCart = cartState.cart;
            updatedCart.shipping_addresses[0].selected_shipping_method =
                data.setShippingMethodsOnCart.cart.shipping_addresses[0].selected_shipping_method;
            updatedCart.prices = data.setShippingMethodsOnCart.cart.prices;
            updatedCart.items = data.setShippingMethodsOnCart.cart.items;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            shippingMethodRef &&
            shippingMethodRef.current &&
            invalidShippingMsg
        ) {
            shippingMethodRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [invalidShippingMsg, cartState]);

    const selectMethod = (methodCode, carrierCode) => {
        setInvalidShippingMsg(null);
        clearNotification();
        setShippingMethod({
            variables: {
                cartId: cartState.cartId,
                carrierCode,
                methodCode
            }
        });
    };

    const triggerPaypal = () => {
        var btn = document.getElementById('paypal-payment-button');
        btn.click();
    };

    return (
        <div className={'shipping-method-form'} ref={shippingMethodRef}>
            <h2 className={'step-title shipping-method-heading'}>
                {t('Shipping Method')}
            </h2>
            {shippingMsg && (
                <Alert variant={'warning'}>
                    {warningIcon ? (
                        warningIcon
                    ) : (
                        <Warning size={14} strokeWidth={'4'} color={'#000'} />
                    )}
                    {t(shippingMsg)}
                </Alert>
            )}
            {invalidShippingMsg && (
                <Alert variant={'warning'}>
                    {warningIcon ? (
                        warningIcon
                    ) : (
                        <Warning size={14} strokeWidth={'4'} color={'#000'} />
                    )}
                    {isFromPaypal &&
                    invalidShippingMsg === 'has-trigger-paypal' ? (
                        <>
                            <span>
                                There are no available shipping methods for this
                                address. Please change your address{' '}
                            </span>
                            <span
                                onClick={() => triggerPaypal()}
                                className={'paypal-trigger'}
                            >
                                here
                            </span>
                        </>
                    ) : (
                        t(invalidShippingMsg)
                    )}
                </Alert>
            )}
            <div className="loading-or-form">
                {loading && (
                    <div className="loading-indicator-container">
                        <LoadingIndicator />
                    </div>
                )}
                {!!cartState.cart.shipping_addresses[0]
                    .available_shipping_methods.length > 0
                    ? !cartState.cart.shipping_addresses[0]
                          .available_shipping_methods[0].available
                        ? cartState.cart.shipping_addresses[0]
                              .available_shipping_methods[0].error_message
                        : cartState.cart.shipping_addresses[0].available_shipping_methods.map(
                              method => (
                                  <div
                                      className={'shipping-method'}
                                      key={
                                          method.carrier_code +
                                          ';' +
                                          method.method_code
                                      }
                                  >
                                      <CustomRadioButton
                                          onClick={() => {
                                              selectMethod(
                                                  method.method_code,
                                                  method.carrier_code
                                              );
                                          }}
                                          active={
                                              cartState.cart
                                                  .shipping_addresses[0]
                                                  .selected_shipping_method &&
                                              cartState.cart
                                                  .shipping_addresses[0]
                                                  .selected_shipping_method
                                                  .carrier_code ===
                                                  method.carrier_code &&
                                              cartState.cart
                                                  .shipping_addresses[0]
                                                  .selected_shipping_method
                                                  .method_code ===
                                                  method.method_code
                                          }
                                          label={`${getCurrencySymbol(method.price_excl_tax.currency)} ${method.price_excl_tax.value} ${method.method_title} ${method.carrier_title}`}
                                      >
                                          <label
                                              htmlFor={`${method.carrier_code}${method.method_code}`}
                                              style={{ cursor: 'pointer' }}
                                          >
                                              <span
                                                  className={
                                                      'shipping-method-price'
                                                  }
                                              >
                                                  {getCurrencySymbol(
                                                      method.price_excl_tax
                                                          .currency
                                                  ) +
                                                      method.price_excl_tax
                                                          .value}
                                              </span>
                                              <span
                                                  className={
                                                      'shipping-method-title'
                                                  }
                                              >
                                                  {method.method_title}
                                              </span>
                                              <span
                                                  className={
                                                      'shipping-method-carrier'
                                                  }
                                              >
                                                  {method.carrier_title}
                                              </span>
                                          </label>
                                      </CustomRadioButton>
                                  </div>
                              )
                          )
                    : !isFromPaypal && (
                          <div>
                              {t(
                                  'No available shipping methods for this address. Please try a different address.'
                              )}
                          </div>
                      )}
            </div>
        </div>
    );
};
